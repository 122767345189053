import { defineStore, storeToRefs } from 'pinia'
import { generateImageUrl, normalizeContentImage, normalizeLogo } from './utils'
import { useUserStore } from '@/store/user'
import type { FetchManyGlobalSettingsQuery, Link } from '~/modules/contentstack/types'
import { getSettingByCountryCode } from '~/common/utils/parsers/cms/globalSettings'
import { DEVKEY, getNavigationQueryParams } from '~/common/constants/cms'

export type GlobalSettingItem = NonNullable<
  NonNullable<NonNullable<FetchManyGlobalSettingsQuery['all_global_settings']>['items']>[number]
>

const staticTopbarLinks = [
  {
    advanced_text: '',
    label: 'Technical support',
    ui_icon: 'cstf-wrench',
    link: {
      href: '/learn-and-support/technical-support',
      title: 'Technical Support',
    },
  },
  {
    advanced_text: '',
    label: 'Contact Us',
    ui_icon: 'cstf-contact-us',
    link: {
      href: '/about-us/contact-us',
      title: 'Contact Us',
    },
  },
  {
    advanced_text: '<p>PhosphoSitePlus<sup>®</sup></p>',
    label: 'PhosphoSitePlus®',
    ui_icon: 'cstf-phosphosite',
    link: {
      href: '/learn-and-support/phosphositeplus-ptm-database',
      title: 'PhosphoSitePlus®',
    },
  },
]
export type AudienceConnection = NonNullable<
  NonNullable<NonNullable<FetchManyGlobalSettingsQuery['all_global_settings']>['items']>[number]
>['audienceConnection']

type GlobalSettingsLogo = {
  parsed: {
    image: ReturnType<typeof normalizeContentImage>
    link: Link
  }
  audienceConnection: AudienceConnection
}

type Social = {
  parsed: Array<
    Extract<
      NonNullable<
        NonNullable<
          NonNullable<
            NonNullable<NonNullable<FetchManyGlobalSettingsQuery['all_global_settings']>['items']>[number]
          >['entities']
        >[number]
      >,
      { __typename: 'GlobalSettingsEntitiesLink' }
    >['link']
  >
  audienceConnection: AudienceConnection
}

type FooterTechPhoneNumber = {
  parsed: {
    title: string
    url: string
  }
  audienceConnection: AudienceConnection
}

type GlobalSettingsValue = {
  parsed: {
    value: string[]
  }
  audienceConnection: AudienceConnection
}

interface CMSGlobalSettingsStore {
  topbarLinks: { parsed: typeof staticTopbarLinks; audienceConnection: AudienceConnection }[]
  showMainNav: boolean
  items: GlobalSettingItem[]
  headerBrandLogo: GlobalSettingsLogo[]
  percentLogo: GlobalSettingsLogo[]
  footerBrandLogo: GlobalSettingsLogo[]
  backgroundImageId: { parsed: String; audienceConnection: AudienceConnection }[]
  copyrightRte: { parsed: any; audienceConnection: AudienceConnection }[]
  social: Social[]
  legalStatement: { parsed: any; audienceConnection: AudienceConnection }[]
  footerTechPhoneNumber: FooterTechPhoneNumber[]
  hidePriceBrick: GlobalSettingsValue[]
  mtoProductList: GlobalSettingsValue[]
  excludedProducts: GlobalSettingsValue[]
  b2dAllowedCountries: GlobalSettingsValue[]
  signalStarSecondaryAntibodyKits: GlobalSettingsValue[]
}
export const useStore = defineStore({
  id: 'cms-global-settings',
  state: (): CMSGlobalSettingsStore => ({
    topbarLinks: [],
    showMainNav: false,
    items: [],
    headerBrandLogo: [],
    percentLogo: [],
    footerBrandLogo: [],
    backgroundImageId: [],
    copyrightRte: [],
    social: [],
    legalStatement: [],
    footerTechPhoneNumber: [],
    hidePriceBrick: [],
    mtoProductList: [],
    excludedProducts: [],
    b2dAllowedCountries: [],
    signalStarSecondaryAntibodyKits: [],
  }),
  getters: {
    getItemFromDevkey: (state) => (devkey: string) => {
      return state.items.filter((item) => item.devkey === devkey)
    },
    getAttributeByCountry:
      (state) =>
      <T extends Exclude<keyof typeof state, 'showMainNav' | 'items'>>(
        attribute: T,
        countryCode?: string
      ): CMSGlobalSettingsStore[T][number]['parsed'] => {
        const country = countryCode ? countryCode : storeToRefs(useUserStore()).selectedCountry.value
        return getSettingByCountryCode(state[attribute], country)?.parsed
      },
  },
  actions: {
    async fetchManyGlobalSettings() {
      const navigationQueryParams = getNavigationQueryParams()
      const { data } = await this.$nuxt.$cms.api.fetchManyGlobalSettings(navigationQueryParams)
      this.setGlobalSettingsData(data)
      return data
    },
    setGlobalSettingsData(data: FetchManyGlobalSettingsQuery) {
      this.$patch({
        items: data.all_global_settings?.items?.map((item) => item!)!,
      })
      this.$patch({
        topbarLinks: buildTopbarLinks(this.getItemFromDevkey(DEVKEY.TECH_SUPPORT_PHONE_NUMBER)),
        headerBrandLogo: buildBrandLogoFromGlobalSettings(this.getItemFromDevkey(DEVKEY.HEADER_LOGO)),
        percentLogo: buildBrandLogoFromGlobalSettings(this.getItemFromDevkey(DEVKEY.LOGO_1_PERCENT)),
        footerBrandLogo: buildBrandLogoFromGlobalSettings(this.getItemFromDevkey(DEVKEY.FOOTER_LOGO)),
        backgroundImageId: buildBackgroundImageId(this.getItemFromDevkey(DEVKEY.COPYRIGHT_BACKGROUND)),
        copyrightRte: getJsonFromContent(this.getItemFromDevkey(DEVKEY.COPYRIGHT)),
        social: buildSocialLinks(this.getItemFromDevkey(DEVKEY.SOCIAL_LINKS)),
        legalStatement: getJsonFromContent(this.getItemFromDevkey(DEVKEY.PDP_LEGAL_STATEMENT)),
        footerTechPhoneNumber: buildFooterTechLinks(this.getItemFromDevkey(DEVKEY.TECH_SUPPORT_PHONE_NUMBER)),
        hidePriceBrick: buildGlobalSettingsValue(this.getItemFromDevkey(DEVKEY.HIDE_PRICE_BRICK)),
        mtoProductList: buildGlobalSettingsValue(this.getItemFromDevkey(DEVKEY.MTO_PRODUCT_LIST)),
        excludedProducts: buildGlobalSettingsValue(this.getItemFromDevkey(DEVKEY.EXCLUDED_PRODUCTS)),
        b2dAllowedCountries: buildGlobalSettingsValue(this.getItemFromDevkey(DEVKEY.B2D_ALLOWED_COUNTRIES)),
        signalStarSecondaryAntibodyKits: buildGlobalSettingsValue(
          this.getItemFromDevkey(DEVKEY.DATASHEET_SIGNALSTAR_SECONDARY_ANTIBODY_KITS)
        ),
      })
    },
    setShowMainNav(val: boolean) {
      this.showMainNav = val
    },
  },
})

const buildBrandLogoFromGlobalSettings = (globalSettingItems: GlobalSettingItem[]) => {
  if (!globalSettingItems) {
    return undefined
  }
  return globalSettingItems.map((item) => {
    const mediaConnection = item?.mediaConnection?.edges?.[0]?.node
    const navigationLink = item?.navigation_linkConnection?.edges?.[0]?.node?.link ?? undefined
    return mediaConnection && navigationLink
      ? { parsed: normalizeLogo(mediaConnection, navigationLink), audienceConnection: item.audienceConnection }
      : undefined
  })
}

const buildBackgroundImageId = (globalSettingItems: GlobalSettingItem[]) => {
  return globalSettingItems.map((item) => {
    const mediaConnection = item?.mediaConnection?.edges?.[0]?.node
    return {
      parsed: mediaConnection ? generateImageUrl({ imageId: mediaConnection?.cloudflare_id }) : '',
      audienceConnection: item.audienceConnection,
    }
  })
}

const getJsonFromContent = (globalSettingItems: GlobalSettingItem[]) => {
  return globalSettingItems.map((item) => {
    return {
      parsed:
        item?.entities?.[0]?.__typename === 'GlobalSettingsEntitiesContent' && item?.entities?.[0]?.content?.text?.json,
      audienceConnection: item.audienceConnection,
    }
  })
}

const buildSocialLinks = (globalSettingItems: GlobalSettingItem[]) => {
  return globalSettingItems.map((item) => {
    return {
      parsed: item?.entities?.map((entity) =>
        entity?.__typename === 'GlobalSettingsEntitiesLink' ? entity.link : undefined
      ),
      audienceConnection: item.audienceConnection,
    }
  })
}

const buildTopbarLinks = (globalSettingItems: GlobalSettingItem[]) => {
  return globalSettingItems.map((item) => {
    const parsedTopbarLinks = [...staticTopbarLinks]
    parsedTopbarLinks.splice(1, 0, {
      advanced_text: '',
      label: `${item.value}`,
      ui_icon: '',
      link: {
        href: `tel:${item.value}`,
        title: `${item.value}`,
      },
    })
    return {
      parsed: parsedTopbarLinks,
      audienceConnection: item.audienceConnection,
    }
  })
}

const buildFooterTechLinks = (globalSettingItems: GlobalSettingItem[]) => {
  return globalSettingItems.map((item) => {
    return {
      parsed: {
        title: `Call: ${item.value}`,
        url: `tel:${item.value}`,
      },
      audienceConnection: item.audienceConnection,
    }
  })
}

const buildGlobalSettingsValue = (globalSettingItems: GlobalSettingItem[]) => {
  return globalSettingItems.map((item) => {
    return {
      parsed: {
        value: item.value?.split(','),
      },
      audienceConnection: item.audienceConnection,
    }
  })
}
